import React from 'react'

interface INotFoundProps {}

export const NotFound:React.FC<INotFoundProps> = () => {
    return (
        <div>
            This is Not Found Page
        </div>
    )
}